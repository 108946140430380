$(document).ready(function () {
    // SLIDER
    var owl = $("#slider");

    owl.owlCarousel({
        navigation: false,
        singleItem: true,
        autoPlay: 8000,
        autoWidth:true,
        transitionStyle: "fadeUp"
    });
    $("#transitionType").change(function () {
        var newValue = $(this).val();
        owl.data("owlCarousel").transitionTypes(newValue);
        owl.trigger("owl.next");
    });
});